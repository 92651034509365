<template>
  <div>
    <button
      aria-label="Scrolle zum ersten Seitenabschnitt"
      class="focus-default mx-auto -mt-2 flex flex-col items-center justify-center px-2 pb-1 pt-2 transition-shadow"
      @click="scrollToFirstSection"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        class="w-8"
        viewBox="0 0 32 52"
      >
        <g
          fill="none"
          stroke="#2f2f2f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        >
          <rect width="32" height="52" stroke="none" rx="16" />
          <rect width="30.5" height="50.5" x=".75" y=".75" rx="15.25" />
        </g>
        <path
          class="arrow-down"
          fill="#2f2f2f"
          d="m10.74 19.22 4.7 4.65a.74.74 0 0 0 1.1 0l4.69-4.65a.82.82 0 0 0 .07-1.06.74.74 0 0 0-1.17-.08l-3.37 3.37v-8.3a.78.78 0 1 0-1.56 0v8.3l-3.36-3.38a.74.74 0 0 0-1.18.08.82.82 0 0 0 .08 1.06Z"
        />
      </svg>

      <span class="text-2xs font-medium">Scrollen</span>
    </button>
  </div>
</template>

<script setup>
const scrollToFirstSection = () => {
  const element = document.querySelector('header + section')
  const headerOffset = 60
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}
</script>

<style scoped>
svg .arrow-down {
  animation: bounce 2.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
